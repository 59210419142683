import React, { useEffect } from 'react';
import { CardTitle } from '../bootstrap/Card';
import SpinnerLogo from '../bootstrap/SpinnerLogo';
import CustomAreaGraph from './CustomAreaGraph';

interface GraphsViewProps {
    data: any;
    loading: boolean;
    title: string;
    className?: string;
    height?: number;
}

const GraphsView: React.FC<GraphsViewProps> = ({ data, loading, title, className, height }) => {

    const [series, setSeries] = React.useState<any[]>([{ data: [] }]);

    useEffect(() => {
        if (data) {
            setSeries([{ data: data.map((item: any) => ({ x: new Date(item.date).getTime(), y: /*item.totalEarned - */   item.earnedForElement  })) }]);
        }
    }, [data]);

    return (
        <div className={`row d-flex justify-content-center ${className}`}>
            <SpinnerLogo loading={loading} />

            {data && (
                <>
                    <CardTitle>{title}</CardTitle>

                    <div key={series[0].data[0]}>
                        <CustomAreaGraph chartId={title} series={series} height={height} />
                    </div>
                </>
            )}
        </div>
    );
};

export default GraphsView;